import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CONSTANTS } from 'src/app/shared/constant/constants';
import * as _ from 'lodash';

@Component({
  selector: 'app-filter-booking',
  templateUrl: './filter-booking.component.html',
  styleUrls: ['./filter-booking.component.scss'],
})
export class FilterBookingComponent implements OnInit {

  status = {
    pending: {
      name: 'pending',
      title: 'Task Pending Approval'
    },
    upcoming: {
      name: 'upcoming',
      title: 'Upcoming’s Task',
    },
    today: {
      name: 'today',
      title: 'Today’s Task'
    },
    inprogress: {
      name: 'inprogress',
      title: 'Task In Progress'
    }
  };
  bookingStatus = CONSTANTS.BOOKING_STATUS_FILTER;
  sortFilter = [
    {
      name: 'Latest Update to Oldest Update',
      value: 'desc',
      checked: true,
    }, {
      name: 'Oldest Update to Latest Update',
      value: 'asc',
      checked: false,
    },
  ];
  selectedSortFilter = this.sortFilter[0].value;
  textSearch: string;

  ALL_OPTION = CONSTANTS.ALL_OPTION;
  BOOKING_TYPE = CONSTANTS.BOOKING_TYPE;
  BOOKING_TYPES = JSON.parse(JSON.stringify(CONSTANTS.BOOKING_TYPES));
  bookingTypeSelected = this.ALL_OPTION;

  companies = [];
  companyIdSelected = this.ALL_OPTION;

  currentStatus;
  constructor(
    private params: NavParams,
    public modalController: ModalController
  ) {
    this.BOOKING_TYPES.unshift({ value: this.ALL_OPTION, name: 'All' });
    this.companies = this.params.get('companies') || [];
    this.currentStatus = this.params.get('currentStatus') || this.status.inprogress.name;
  }

  ngOnInit() {
    this.bookingStatus.map(item => {
      item.checked = false;
    });
  }

  close() {
    this.modalController.dismiss();

  }

  onSearch() {
    const selectedItems = _.filter(this.bookingStatus, (item) => item.checked);

    const selectedStatus = selectedItems && selectedItems.length ? selectedItems.map(item => item.value) : [];

    this.modalController.dismiss({
      bookingStatus: selectedStatus,
      order: this.selectedSortFilter,
      key: this.textSearch,
      bookingTypeSelected: this.bookingTypeSelected,
      companyIdSelected: this.companyIdSelected
    });
  }
}
