import { Injectable } from "@angular/core";
import { ToastController, AlertController } from "@ionic/angular";
import { CONSTANTS } from '../../constant/constants';
@Injectable()
export class Utils {
  loading: any;
  errorMessage = 'An error occurred, please try again later!';
  constructor(
    private alertCtrl: AlertController,
    private toastController: ToastController,
  ) { }
  showConstantName(constants, value) {
    if (!constants) {
      return '';
    }
    const arrays = Object.keys(constants).map(key => constants[key]);
    const item = arrays.find(item => item.value === value);

    return item && item.name || '';
  }

  async showAlertError(message) {
    let alert = await this.alertCtrl.create({
      header: CONSTANTS.MESSAGES.ALERT_ERROR_HEADER,
      message: `<p>${message}</p>`,
      cssClass: 'alert-error',
      buttons: ['Ok']
    });
    await alert.present();
  }

  showAlertConfirm(message) {
    return new Promise(async (res, rej) => {
      let alert = await this.alertCtrl.create({
        header: CONSTANTS.MESSAGES.COMFIRM_HEADER,
        message: `<p>${message}</p>`,
        cssClass: '',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          },
          {
            text: 'Ok',
            handler: () => {
              res(true)
            }
          }
        ]
      });
      await alert.present();
    })
  }

  async showToastOnTop(msg) {
    const toast = await this.toastController.create({
      position: "top",
      message: msg,
      duration: 1500
    });
    toast.present();
  }

  async showToastBottom(msg) {
    const toast = await this.toastController.create({
      position: "bottom",
      message: msg,
      duration: 1500
    });
    toast.present();
  }

  async showToastSuccess(msg) {
    const toast = await this.toastController.create({
      position: "top",
      message: msg,
      color: 'success',
      cssClass: 'text-center',
      duration: 1500
    });
    toast.present();
  }

  async showToastError(msg?, position?) {
    const toast = await this.toastController.create({
      position: position || "top",
      message: msg || this.errorMessage,
      color: 'danger',
      cssClass: 'text-center',
      duration: 1500
    });
    toast.present();
  }

  handleError(error?) {
    const statusCode = error && error.status;
    const message = statusCode && statusCode === CONSTANTS.STATUS_CODE.INTERNAL_SERVER_ERROR ? this.errorMessage
      : (error && error.message || this.errorMessage);

    return this.showToastError(message);
  }

  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  showAlertConfirmWithInput(message, name, placeholder) {
    return new Promise(async (res, rej) => {
      const alert = await this.alertCtrl.create({
        header: CONSTANTS.MESSAGES.COMFIRM_HEADER,
        message: `<p>${message}</p>`,
        cssClass: '',
        inputs: [
          {
            type: 'text',
            name,
            placeholder,
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          },
          {
            text: 'Yes',
            handler: (data) => {
              if (!data[name]) {
                alert.message = `<p>${message}</p><br/>
                <p class="alert-message-error">${name} is required</p>`;
                return false;
              }

              res(data);
            }
          }
        ]
      });
      await alert.present();
    });
  }
}
