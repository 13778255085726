import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CONSTANTS } from '../../constant/constants';
import { DateTimeService } from '../../services/utils/date_time.service';
import { environment as config } from '../../../../environments/environment';

@Component({
  selector: 'app-task-info',
  templateUrl: './task-info.component.html',
  styleUrls: ['./task-info.component.scss'],
})
export class TaskInfoComponent implements OnInit {
  @Input() booking: any;
  @Input() type: string;
  @Output() selectedTask: EventEmitter<any> = new EventEmitter();
  @Input() isShowComment = false;

  /**
   * https://gocartech.atlassian.net/browse/GCG-286
   * Temporary solution for special request(Sai awared)
   */
  specialGarageId = config.GARAGE_PJ_B2B_ID;

  taskType = CONSTANTS.TASK_TYPE;

  constructor(
    public dateTimeService: DateTimeService
  ) { }

  ngOnInit() { }

  onSelectTask() {
    this.selectedTask.emit();
  }

}
