export const ROUTES = {
    LOGIN: 'login',
    SUBMIT_OTP: 'submit-otp',
    HOME: 'home',
    GARAGE_SELECTION: 'garage-selection',
    LIST_TASKS: 'list-tasks',
    BOOKING: 'booking',
    CALENDAR: 'calendar',
    TASK: 'task',
    LIST_BOOKINGS: 'list-bookings',
    SPARE_PART_SHOP: {
        ROOT: 'spare-part-shop',
        HOME: '',
        ORDER_HISTORY: 'order-history',
        VIEW_CART: 'view-cart',
        ORDER_ITEM_LIST: ':id/order-item-list',
        ORDER_SUCCESS: 'order-success',
        PRODUCT_DETAIL: 'detail',
    }
}
