import { Headers, Http, Response, RequestOptions } from '@angular/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { Observable, Observer } from 'rxjs/Rx';
import * as _ from 'lodash';
import { environment as config } from '../../../environments/environment';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ROUTES } from '../constant/routes';
import { CONSTANTS } from '../constant/constants';

@Injectable()
export class BaseService {
    OBJECT_ERROR = { "code": 400, "message": "No internet connection detected. Please check and try again." };
    UNAUTHORIZED_ERROR = { "code": 401, "message": "Your session has expired. Please log in again to continue." };
    UNEXPECTED_ERROR = { "code": 400, "message": "An error occurred, please try again later!" };

    apiUrl = '';

    constructor(
        protected http: Http,
        protected router: Router,
        protected navCtrl: NavController
    ) {
        this.apiUrl = config.API_URL_GOCAR_GARAGE
    }

    protected getData(path: string, params?): Observable<any> {
        return this.getToken()
            .flatMap(token => this.getHeaders(token, null, params))
            .flatMap((options) => this.http.get(`${this.apiUrl}/${path}`, options))
            .map((res: Response) => res.json())
            .catch((err) => {
                return this.getError(err);
            });
    }

    protected postData(path: string, body?: any, headersPairs?: any): Observable<any> {
        return this.getToken()
            .flatMap(token => this.getHeaders(token, headersPairs))
            .flatMap((options) => this.http.post(`${this.apiUrl}/${path}`, body, options))
            .catch((err) => {
                return this.getError(err);
            });
    }

    protected deleteData(path: string): Observable<any> {
        return this.getToken()
            .flatMap((token) => this.getHeaders(token))
            .flatMap((options) => this.http.delete(`${this.apiUrl}/${path}`, options))
            .map((res: Response) => res.json())
            .catch((err) => {
                return this.getError(err);
            });
    }

    protected putData(path: string, body: any): Observable<any> {
        return this.getToken()
            .flatMap((token) => this.getHeaders(token))
            .flatMap((options) => this.http.put(`${this.apiUrl}/${path}`, body, options))
            .map((res: Response) => res.json())
            .catch((err) => {
                return this.getError(err);
            });
    }

    protected getError(err) {
        if ([CONSTANTS.STATUS_CODE.UNAUTHORIZED, CONSTANTS.STATUS_CODE.FORBIDDEN].indexOf(err.status) !== -1) {
            localStorage.clear();
            this.navCtrl.navigateForward([`${ROUTES.HOME}/${ROUTES.LOGIN}`]);
            return Observable.throw(this.UNAUTHORIZED_ERROR)
        }

        if (!err.json().message) {
            return Observable.throw(this.UNEXPECTED_ERROR)
        }

        return Observable.throw(err.json());
    }

    private getHeaders(token?: string, headersPairs?: any, params?: any): Observable<RequestOptions> {
        return Observable.create((observer: Observer<RequestOptions>) => {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');

            // add garageId selected
            const user = localStorage.getItem('user_merchant') ? JSON.parse(localStorage.getItem('user_merchant')) : null
            if (user) {
                headers.append('garage_id', user.garageId);
            }

            if (token) {
                headers.append('merchant_token', token);
            }

            switch (this.apiUrl) {
                case config.API_URL_GOCAR_GARAGE:
                    headers.append(config.SECRET_KEY_GOCAR_GARAGE, config.SECRET_VALUE_GOCAR_GARAGE);
                    break;

                default:
                    break;
            }

            if (headersPairs) {
                _.forEach(headersPairs, (value, key) => {
                    headers.append(key, value);
                })
            }

            const options = new RequestOptions({ headers, params });

            observer.next(options);
            observer.complete();
        })
    }

    protected postDataLogin(path: string, body?: any, headersPairs?: any): Observable<any> {
        return this.getToken()
            .flatMap((token) => this.getHeadersLogin(token, headersPairs))
            .flatMap((options) => this.http.post(`${this.apiUrl}/${path}`, body, options))
            .catch((err) => {
                return this.getError(err);
            });
    }

    private getToken(): Observable<string> {
        return Observable.create((observer: Observer<string>) => {
            let token = localStorage.getItem('merchant_token');
            if (token) {
                observer.next(token);
                observer.complete();
            }
            observer.next(null);
            observer.complete();

        })
    }

    private getHeadersLogin(token?: string, headersPairs?: any, params?: any): Observable<RequestOptions> {
        return Observable.create((observer: Observer<RequestOptions>) => {
            const headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append(config.SECRET_KEY_GOCAR_GARAGE, config.SECRET_VALUE_GOCAR_GARAGE);
            if (headersPairs) {
                _.forEach(headersPairs, (value, key) => {
                    headers.append(key, value);
                })
            }
            let options = new RequestOptions({ headers, params });
            observer.next(options);
            observer.complete();
        })
    }
}
