import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../constant/constants';
import { ROUTES } from '../../constant/routes';
import { LocalStorageService } from '../../services/storage/localstorage.service';
import { Utils } from '../../services/utils/utils.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-garage-info',
  templateUrl: './garage-info.component.html',
  styleUrls: ['./garage-info.component.scss'],
})
export class GarageInfoComponent implements OnInit {
  garageName = '';
  @Input() isShowHome: boolean
  @Input() isShowLogout: boolean
  @Input() prodItems: boolean
  @Input() isShowCart: boolean

  isShowSelectGarage: boolean;
  count: any;
  constructor(
    private userService: UserService,
    private router: Router,
    private utils: Utils,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    const userInfo = this.userService.getUserLocal();
    this.garageName = userInfo && userInfo.garageName || '';
    this.isShowSelectGarage = userInfo.garageId && userInfo && userInfo.accessGarages && userInfo.accessGarages.length > 1;
  }

  viewCart() {
    this.router.navigate([`/home/${ROUTES.SPARE_PART_SHOP.ROOT}/${ROUTES.SPARE_PART_SHOP.VIEW_CART}`], { replaceUrl: true })
  }

  goHome() {
    this.router.navigate(['/home'], { replaceUrl: true })
  }

  goGarageSelection() {
    this.router.navigate(['/home/garage-selection'], { replaceUrl: true })
  }

  async logout() {
    let ok = await this.utils.showAlertConfirm(CONSTANTS.MESSAGES.LOGOUT_CONFIRMATION)
    if (ok) {
      this.userService.logOut();
      this.router.navigate(['/home/login'], { replaceUrl: true })
    }
  }

  countItem() {
    const userInfor = this.localStorageService.getData(CONSTANTS.USER_MERCHANT);
    const cartItems = userInfor.cart || [];
    const count = cartItems.length;

    return count || 0;
  }
}
