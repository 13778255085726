import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'pricingFormat'
})
export class PricingFormatPipe implements PipeTransform {
  constructor(
    private _decimalPipe: DecimalPipe
  ) { }

  transform(price: Number): any {
    if (!price) {
      return 'RM0.00';
    }

    return `RM${this._decimalPipe.transform(price, '1.2-2')}`;
  }
}