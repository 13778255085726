import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarComponentOptions } from 'ion2-calendar';
import { Utils } from '../../services/utils/utils.service';
import { GoCarPopover } from '../popover/gocar-popover';

@Component({
  selector: 'app-popup-calendar',
  templateUrl: './popup-calendar.component.html',
  styleUrls: ['./popup-calendar.component.scss'],
})
export class PopupCalendarComponent implements OnInit {
  dateRange: { from: string; to: string; };
  type: 'string'; // 'string' | 'js-date' | 'moment' | 'time' | 'object'
  optionsRange: CalendarComponentOptions = {
    pickMode: 'range',
    from: new Date().setFullYear(2020),
    showToggleButtons: true,
    showMonthPicker: true
  };

  constructor(
    private goCarPopover: GoCarPopover,
    private utils: Utils
  ) { }

  ngOnInit() { }

  submit() {
    if (!this.dateRange || !this.dateRange.from || !this.dateRange.to) {
      return this.utils.showToastError("Range date invalid!");
    }
    this.goCarPopover.dismiss(this.dateRange)
  }
}
