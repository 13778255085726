import { Component, EventEmitter } from "@angular/core";
import { PopoverOptions } from '@ionic/core';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'gocar-popover',
    templateUrl: 'gocar-popover.html'
})
export class GoCarPopover {
    dismissEvent: EventEmitter<any> = new EventEmitter()
    popover: any
    data: any
    constructor(public popoverCtrl: PopoverController) {
    }

    public show(opt: PopoverOptions, data?): Promise<any> {
        this.data = data || null;
        opt.translucent = false
        return new Promise(async (resolve, reject) => {
            this.popover = await this.popoverCtrl.create(opt)
            await this.popover.present();
            this.popover.onDidDismiss().then(result => {
                this.popover = null
                resolve(result.data);
            })
        })
    }

    public getPopoverActive() {
        return this.popover
    }

    dismiss(data?) {
        return this.popoverCtrl.dismiss(data);
    }

    get(key: string) {
        if (!this.data) {
            return null
        }
        return this.data[key];
    }
}