import { Component, Input, OnInit } from '@angular/core';
import { CONSTANTS } from '../../constant/constants';
import { DateTimeService } from '../../services/utils/date_time.service';
import { Utils } from '../../services/utils/utils.service';
import { environment as config } from '../../../../environments/environment';

@Component({
  selector: 'app-booking-info',
  templateUrl: './booking-info.component.html',
  styleUrls: ['./booking-info.component.scss'],
})
export class BookingInfoComponent implements OnInit {
  @Input() booking: any;
  CONSTANTS = CONSTANTS

  /**
   * https://gocartech.atlassian.net/browse/GCG-286
   * Temporary solution for special request(Sai awared)
   */
  specialGarageId = config.GARAGE_PJ_B2B_ID;

  constructor(
    public dateTimeService: DateTimeService,
    public utils: Utils,
  ) { }

  ngOnInit() { }

}
