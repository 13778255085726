import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-modal-confirm-oil-change',
  templateUrl: './modal-confirm-oil-change.component.html',
  styleUrls: ['./modal-confirm-oil-change.component.scss'],
})
export class ModalConfirmOilChangeComponent implements OnInit {
  isChange = undefined
  constructor(public popoverCtrl: PopoverController) { }

  ngOnInit() { }

  submit() {
    this.popoverCtrl.dismiss({ isChange: this.isChange })
  }
}
