import { Component, Input, OnInit } from '@angular/core';
import { IBreadCrumb } from './breadcrumn.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {

  @Input() breadcrumbs: IBreadCrumb[] = [];
  constructor(
    private router: Router,
  ) { }

  ngOnInit() {}

  navigate(item: IBreadCrumb) {
    if (item.disabled) {
      return;
    }
    if (item.params) {
      return this.router.navigateByUrl(item.url, {
        queryParams: item.params,
        replaceUrl: true
      });
    }

    return this.router.navigateByUrl(item.url, { replaceUrl: true });
  }
}
