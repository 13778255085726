import { Component, OnInit } from '@angular/core';
import { Events } from '@ionic/angular';
import { GoCarPopover } from '../../popover/gocar-popover';

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss'],
})
export class CategoryFilterComponent implements OnInit {
  categories = [];
  brands: any[] = [];
  isShowBrand = true;
  isShowType = true;
  constructor(
    private navParams: GoCarPopover,
    public events: Events,
    private goCarPopover: GoCarPopover,
  ) {
    this.categories = this.navParams.get('categories');
    this.brands = this.navParams.get('brands');

  }

  ngOnInit() {}

  search() {
    this.goCarPopover.dismiss({
      categories: this.categories,
      brands: this.brands
    });
  }

}
