import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANTS } from './shared/constant/constants';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  currentLocation: object;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
  ) {
    this.translate.addLangs([CONSTANTS.LANG_CODE.ENGLISH, CONSTANTS.LANG_CODE.MALAYSIA]);
    this.translate.setDefaultLang(CONSTANTS.LANG_CODE.ENGLISH);

    const browserLang = translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|ms/) ? browserLang : CONSTANTS.LANG_CODE.ENGLISH);

    this.initializeApp();
  }

  ngOnInit(): void {
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  calDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
    var latTmp1 = this.toRad(lat1);
    var latTmp2 = this.toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(latTmp1) *
      Math.cos(latTmp2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return (Value * Math.PI) / 180;
  }
}
