import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss'],
})
export class CtaButtonComponent implements OnInit {
  @Input() type: string;
  @Input() link: string;

  CTA_BUTTON_TYPE = {
    QUOTATION: 'quotation',
    INVOICE: 'invoice',
    LPO: 'lpo'
  }
  textButton = '';
  constructor() { }

  ngOnInit() {
    switch (this.type) {
      case this.CTA_BUTTON_TYPE.QUOTATION:
        this.textButton = 'Quotation';
        break;
      case this.CTA_BUTTON_TYPE.INVOICE:
        this.textButton = 'Invoice';
        break;
      case this.CTA_BUTTON_TYPE.LPO:
        this.textButton = 'LPO';
        break;

      default:
        break;
    }
  }

}
