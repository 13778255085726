import { Component, Input, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { CONSTANTS } from '../../constant/constants';
import { ROUTES } from '../../constant/routes';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-header-garage',
  templateUrl: './app-header-garage.component.html',
  styleUrls: ['./app-header-garage.component.scss'],
})
export class AppHeaderGarageComponent implements OnInit {
  @Input() title: string;
  @Input() hideBack: Boolean;

  isLoggedIn = false;
  constructor(
    private navCtrl: NavController,
    private userService: UserService,
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() {
    const loggedUser = this.userService.getUserLocal();
    this.isLoggedIn = Boolean(loggedUser);
  }

  close() {
    this.navCtrl.pop();
  }

  async logout() {
    let alert = await this.alertCtrl.create({
      header: CONSTANTS.MESSAGES.COMFIRM_HEADER,
      message: `<p>${CONSTANTS.MESSAGES.LOGOUT_CONFIRMATION}</p>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Ok',
          handler: () => {
            this.confirmLogout();
          }
        }
      ]
    });
    await alert.present();
  }

  async confirmLogout() {
    this.userService.logOut();
    this.navCtrl.navigateRoot(`${ROUTES.HOME}`);
  }
}
