export const environment = {
  production: false,
  appVersion: require('../../package.json').version,

  // API_URL_GOCAR_GARAGE: 'https://test-prod---gocar-garage-api-ao3o4udqhq-as.a.run.app/gocar-garage-api',
  API_URL_GOCAR_GARAGE: 'https://garage-api.gocardev.com/gocar-garage-api',
  // API_URL_GOCAR_GARAGE: 'http://localhost:8031/gocar-garage-api',
  // PDF_SERVICE_API: 'http://localhost:80/api',
  PDF_SERVICE_API: 'https://staging2.gocar.my/pdf-service-testing/api',
  SECRET_KEY_GOCAR_GARAGE: "gogarage",
  SECRET_VALUE_GOCAR_GARAGE: "8HFsEl6TpTtDwy1P3vCWHLgqpXI6Z1wP",

  GOCAR_HELPER_API_URL: 'https://dev-gocar-helper-api-3i65asokvq-as.a.run.app',
  GOCAR_HELPER_SECRET_KEY: "gocar_helper_key",
  GOCAR_HELPER_SECRET_VALUE: "2XsE3ydpFukcObeEp6QV8IKJZInhDdmW",

  GARAGE_PJ_B2B_ID: "c54e7dd0-e2e0-11ec-8604-e512e628f3a9",

  firebaseConfig: {
    apiKey: "AIzaSyDEMWGrK31Ya8YWCHCB_MAB9dnXTzAavh4",
    authDomain: "fir-app-2c3a2.firebaseapp.com",
    databaseURL: "https://fir-app-2c3a2.firebaseio.com",
    projectId: "fir-app-2c3a2",
    storageBucket: "fir-app-2c3a2.appspot.com",
    messagingSenderId: "1099234430996"
  },
  
  // firebaseConfig: {
  //   apiKey: "AIzaSyAs0XnDWAxyxUV31xdkVsrFLiZ0VP2Um5A",
  //   authDomain: "test-fml-delivery.firebaseapp.com",
  //   databaseURL: "https://test-fml-delivery.firebaseio.com",
  //   projectId: "test-fml-delivery",
  //   storageBucket: "test-fml-delivery.appspot.com",
  //   messagingSenderId: "436449549840",
  //   appId: "1:436449549840:web:578df9dedfa0edbcd97495"
  // },

  siteKeyCaptcha: "6LfIwawUAAAAAFKkkeXdwYjRAOZFimjnoaFzHA9a",
};
