import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class BookingsService extends BaseService {
  url = 'merchant/bookings'
  constructor(
    public http: Http,
    public router: Router,
    public navCtrl: NavController
  ) {
    super(http, router, navCtrl)
  }

  getForHome() {
    return this.getData(`${this.url}/home`);
  }

  getBookingById(id) {
    return this.getData(`${this.url}/${id}`);
  }

  approve(id, body) {
    return this.postData(`${this.url}/${id}/approve`, body);
  }

  cancel(id, body) {
    return this.postData(`${this.url}/${id}/cancel`, body);
  }

  updateCarArrived(id, body) {
    return this.postData(`${this.url}/${id}/car-arrived`, body);
  }

  updateInspection(id, body) {
    return this.postData(`${this.url}/${id}/inspection`, body);
  }

  updateQuotation(id, body) {
    return this.postData(`${this.url}/${id}/quotation`, body);
  }

  updateStartRepair(id, body) {
    return this.postData(`${this.url}/${id}/start-repairing`, body);
  }

  updateFinishRepair(id, body) {
    return this.postData(`${this.url}/${id}/finish-repairing`, body);
  }

  updateEstimateComletionDate(id, body) {
    return this.postData(`${this.url}/${id}/update-estimate-completion-date`, body);
  }

  updateInvoice(id, body) {
    return this.postData(`${this.url}/${id}/invoice`, body);
  }

  confirmPaidAtCounter(id, body) {
    return this.postData(`${this.url}/${id}/paid-at-counter`, body);
  }

  confirmCollectionPending(id, body) {
    return this.postData(`${this.url}/${id}/collection-pending`, body);
  }

  updateEndTask(id, body) {
    return this.postData(`${this.url}/${id}/end-task`, body);
  }

  addComment(comment) {
    return this.postData(`${this.url}/comments`, comment).map((res: Response) => res.json());
  }

  getForTaskListing(tab = null, page?, limit?) {
    return this.getData(`${this.url}/task-listing`, { tab, page, limit });
  }

  reject(id, body) {
    return this.postData(`${this.url}/${id}/reject`, body);
  }

  getBookingListing(status, key?, bookingStatus?, order?, type?, companyId?) {
    return this.getData(`${this.url}/booking-listing`, { status, key, bookingStatus, order, type, companyId });
  }

  getBookingsByRangeDate(body?) {
    return this.getData(`${this.url}/bookings-by-range-date`, body);
  }

  fileExtraction(body) {
    return this.postData(`${this.url}/request-extraction`, body).map((res: Response) => res.json());;
  }
}
