import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends BaseService {
  url = 'merchant/companies'
  constructor(
    public http: Http,
    public router: Router,
    public navCtrl: NavController
  ) {
    super(http, router, navCtrl)
  }

  getAll() {
    return this.getData(`${this.url}/all`);
  }
}