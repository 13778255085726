import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BaseService } from '../services/base.service';

@Injectable()
export class UserService extends BaseService {
  constructor(public http: Http, public router: Router, public navCtrl: NavController) {
    super(http, router, navCtrl)
  }

  requestOTP(phoneNumber, reCaptchaToken) {
    return this.postData(`auth/request-otp`, { phoneNumber, reCaptchaToken });
  }

  verifyOTP(phoneNumber, otpNumber): Observable<any> {
    let authHeader = { 'gocar-authentication': `${phoneNumber}:${otpNumber}` };
    return this.postDataLogin(`auth/verify-otp`, null, authHeader)
      .map(this.handleAuthResponse.bind(this))
      .catch((err) => {
        console.log(err);
        return Observable.throw(err);
      });;
  }

  private handleAuthResponse(res: Response): boolean {
    let returnData = res.json()['data']
    let token = returnData.token;
    localStorage.setItem('merchant_token', token)
    this.setUserLocal(returnData)
    return returnData;
  }

  getUserLocal() {
    if (localStorage.getItem('user_merchant')) {
      return JSON.parse(localStorage.getItem('user_merchant'))
    }
  }

  public setUserLocal(user) {
    localStorage.setItem('user_merchant', JSON.stringify(user))
  }

  async removeUserLocal() {
    localStorage.removeItem('user_merchant')
    localStorage.removeItem('merchant_token');
    localStorage.removeItem('watchId');
  }

  logOut() {
    this.removeUserLocal();
  }
}
