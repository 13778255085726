import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class SparePartShopService extends BaseService {
  url = 'merchant/spare-part-shop'
  constructor(
    public http: Http,
    public router: Router,
    public navCtrl: NavController
  ) {
    super(http, router, navCtrl)
  }

  getForHome(condition) {
    return this.getData(`${this.url}/home`, condition);
  }

  getAllCategory() {
    return this.getData(`${this.url}/all-category`);
  }

  getOrderHistory() {
    return this.getData(`${this.url}/order-history`);
  }

  getOrderItems(orderId: string) {
    return this.getData(`${this.url}/${orderId}/order-items`);
  }

  checkout(data) {
    return this.postData(`${this.url}/checkout`, data);
  }

  cancelOrder(data) {
    return this.postData(`${this.url}/cancel`, data);
  }

  getProductById(id) {
    return this.getData(`${this.url}/product/${id}`);
  }

  getAllBrand() {
    return this.getData(`${this.url}/all-brand`);
  }
}
