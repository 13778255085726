import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { Utils } from '../../services/utils/utils.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  @Input() title: string

  constructor(
    private utils: Utils,
    private navCtrl: NavController,
    private popoverCtrl: PopoverController,
  ) {

  }

  ngOnInit(): void {
  }

  close() {
    this.navCtrl.back();
  }
}
