import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage'
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Upload } from '../../models/upload';
import 'firebase/storage';
import { Events } from '@ionic/angular';
import { environment } from '../../../../environments/environment';

@Injectable()
export class UploadFileService {
  uploads: Observable<Upload[]>;
  apiUrl = environment.GOCAR_HELPER_API_URL;

  constructor(
    private storage: AngularFireStorage,
    private events: Events
  ) { }

  /**
   * @deprecated no longer use function to upload firebase directly
   * Please us new function: uploadToGCP
   * @param fileUpload 
   * @param filePath
   * @returns a downloadable url
   */
  pushUpload(fileUpload: Upload, filePath: string) {
    return new Promise((resolve, reject) => {
      const storageRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, fileUpload.file);
      uploadTask.snapshotChanges().pipe(
        map(snapshot => {
          // upload in progress
          const snap = snapshot;
          const progress = Math.floor((snap.bytesTransferred / snap.totalBytes) * 100)
          this.events.publish("progressEvent", { progress: progress - fileUpload.progress })
          fileUpload.progress = progress
        }),
        finalize(() => {
          storageRef.getDownloadURL().subscribe(downloadURL => {
            resolve(downloadURL)
          }, err => {
            reject(err)
          });
        })
      ).subscribe();
    })
  }

  /**
   * Call backend to upload file to GCP
   * @param fileUpload 
   * @param filePath path of folder, dont include filename bcoz it was generated by server
   * @returns a downloadable url
   */
  async uploadToGCP(fileUpload: Upload, path?: string) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('file', fileUpload.file);

      const url = `${this.apiUrl}/storages/upload?path=${path}`;
      var xhr = new XMLHttpRequest();
      xhr.open('post', url, true);
      xhr.setRequestHeader(environment.GOCAR_HELPER_SECRET_KEY, environment.GOCAR_HELPER_SECRET_VALUE);
      xhr.responseType = 'json';

      const self = this;
      xhr.upload.onprogress = function (e) {
        if (e.lengthComputable) {
          const progress = (e.loaded / e.total) * 100;
          const addedProgress = progress - (fileUpload.progress || 0);
          console.log(`${fileUpload.name} - Current Progress: ${progress}%, Last Progress: ${fileUpload.progress}%, addedProgress: ${addedProgress}%`);

          self.events.publish("progressEvent", { progress: addedProgress })
          // lastProgress = progress
          fileUpload.progress = progress;
        }
      };
      xhr.onreadystatechange = function (a) {
        if (xhr.readyState == XMLHttpRequest.DONE) {
          try {
            resolve(xhr.response.data);
          } catch (error) {
            reject('Cannot upload');
          }
        }
      }

      xhr.onerror = function (e) {
        console.log('Error');
        console.log(e);
      };
      xhr.onload = function () {
        console.log(this.statusText);
      };

      xhr.send(formData);

      // this.http.post(, )
    });
  }

  // uploadDirectFileToFirebase(upload: Upload, path) {
  //   return new Promise((resolve, reject) => {
  //     let seft = this;
  //     const storageRef = firebase.storage().ref();
  //     const uploadTask = storageRef.child(`${path}`).put(upload.file);
  //     uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
  //       (snapshot: firebase.storage.UploadTaskSnapshot) => {
  //         // upload in progress
  //         const snap = snapshot;
  //         const progress = Math.floor((snap.bytesTransferred / snap.totalBytes) * 100)
  //         seft.events.publish("progressEvent", { progress: progress - upload.progress })
  //         upload.progress = progress
  //       },
  //       (error) => {
  //         // upload failed
  //         console.log(error);
  //       },
  //       () => {
  //         // upload success
  //         uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
  //           resolve(downloadURL)
  //         });
  //       },
  //     );
  //   })
  // }
}
