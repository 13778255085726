import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AppHeaderComponent } from './component/app-header/app-header.component';
import { DateTimeService } from './services/utils/date_time.service';
import { LocalStorageService } from './services/storage/localstorage.service';
import { UserService } from './user/user.service';
import { AppHeaderGarageComponent } from "./component/app-header-garage/app-header-garage.component";
import { UploadFileService } from "./services/upload-file/upload-file.service";
import { AngularFireStorage } from "@angular/fire/storage";
import { AuthLoggedIn } from "./auth/auth-logged-in";
import { AppVersionComponent } from "./component/app-version/app-version.component";
import { GarageInfoComponent } from "./component/garage-info/garage-info.component";
import { BookingsService } from "./services/bookings/bookings.service";
import { LoadingComponent } from "./component/loading/loading";
import { UploadProgressComponent } from "./component/upload-progress/upload-progress";
import { GoCarPopover } from "./component/popover/gocar-popover";
import { HttpModule } from "@angular/http";
import { BookingInfoComponent } from "./component/booking-info/booking-info.component";
import { CtaButtonComponent } from "./component/cta-button/cta-button.component";
import { TaskInfoComponent } from "./component/task-info/task-info.component";
import { FilterBookingComponent } from "../home/search/filter-booking/filter-booking.component";
import { SparePartShopService } from "./services/shop/shopping-cart.service";
import { CategoryFilterComponent } from "./component/category-filter/category-filter/category-filter.component";
import { OrderItemListComponent } from "../home/spare-part-shop/order-item-list/order-item-list.component";
import { GocarPipeModule } from "./pipe/gocar-pipe.module";
import { CompaniesService } from "./services/companies/companies.service";
import { BreadcrumbComponent } from "./component/breadcrumb/breadcrumb.component";
import { SocketService } from "./services/socket/socket.service";
import { QuotationCreationComponent } from "./component/quotation-creation/quotation-creation.component";
import { PDFService } from "./services/pdf_service/pdf_service.service";
import { PopupCalendarComponent } from "./component/popup-calendar/popup-calendar.component";
import { CalendarModule } from "ion2-calendar";
import { ModalConfirmOilChangeComponent } from "./component/modal-confirm-oil-change/modal-confirm-oil-change.component";

const Components = [
    AppHeaderComponent,
    AppHeaderGarageComponent,
    AppVersionComponent,
    GarageInfoComponent,
    UploadProgressComponent,
    BookingInfoComponent,
    GoCarPopover,
    LoadingComponent,
    CtaButtonComponent,
    TaskInfoComponent,
    FilterBookingComponent,
    CategoryFilterComponent,
    OrderItemListComponent,
    BreadcrumbComponent,
    QuotationCreationComponent,
    PopupCalendarComponent,
    BreadcrumbComponent,
    ModalConfirmOilChangeComponent
];

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        RouterModule,
        FormsModule,
        HttpModule,
        ReactiveFormsModule,
        GocarPipeModule,
        CalendarModule
    ],
    providers: [
        // Utils,
        DateTimeService,
        LocalStorageService,
        AuthLoggedIn,
        UserService,
        UploadFileService,
        AngularFireStorage,
        BookingsService,
        SparePartShopService,
        CompaniesService,
        SocketService,
        PDFService
    ],
    declarations: [
        ...Components
    ],
    entryComponents: [
        ...Components
    ],
    exports: [
        ...Components
    ],
})
export class SharedModule { }
