import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { PDFService } from '../../services/pdf_service/pdf_service.service';
import { LocalStorageService } from '../../services/storage/localstorage.service';
import { Utils } from '../../services/utils/utils.service';
import { LoadingComponent } from '../loading/loading';
import * as _ from 'lodash';
import { GoCarPopover } from 'src/app/shared/component/popover/gocar-popover';
import { DecimalPipe } from '@angular/common';
import { ModalConfirmOilChangeComponent } from '../modal-confirm-oil-change/modal-confirm-oil-change.component';

@Component({
  selector: 'app-quotation-creation',
  templateUrl: './quotation-creation.component.html',
  styleUrls: ['./quotation-creation.component.scss'],
})
export class QuotationCreationComponent implements OnInit {

  listJobs = []
  quotation = ''
  invoiceId = ''
  jobDetail = ''
  remark = ''
  unitPrice
  quantity
  amount
  today = moment().format('DD MMM YYYY')
  date = moment().format('YYYY-MM-DD')
  index
  isShowPreview = false
  userInfo
  pdfUrl
  pdfName
  bookingInfo: any = {}
  totalAmount = 0
  isGenerateInvoice = false
  @ViewChild('templatePdf') templatePdf: ElementRef;
  constructor(
    private utils: Utils,
    public popoverCtrl: PopoverController,
    private localStorageService: LocalStorageService,
    private PDFService: PDFService,
    private loadingComponent: LoadingComponent,
    private goCarPopover: GoCarPopover,
    private decimalPipe: DecimalPipe
  ) {
    this.userInfo = localStorageService.getData('user_merchant')
    this.bookingInfo = this.goCarPopover.get('bookingInfo') || {};
    this.isGenerateInvoice = this.bookingInfo.isGenerateInvoice
  }

  ngOnInit() { }

  addJob() {
    if (!this.jobDetail) {
      this.utils.showToastError('Please input Job Detail!')
      return
    }
    if (!this.unitPrice) {
      this.utils.showToastError('Please input Unit Price!')
      return
    }
    if (!this.quantity) {
      this.utils.showToastError('Please input Quantity!')
      return
    }

    const job = {
      jobDetail: this.jobDetail,
      unitPrice: this.unitPrice,
      quantity: this.quantity,
      date: this.date,
      amount: this.amount
    }
    if (this.index) {
      this.listJobs[this.index - 1] = job
    } else {
      this.listJobs.push(job)
    }
    this.callTotalAmount()
    this.jobDetail = ''
    this.unitPrice = undefined
    this.quantity = undefined
    this.amount = undefined
    this.index = undefined

  }

  editJob(item, index) {
    this.jobDetail = item.jobDetail
    this.unitPrice = item.unitPrice
    this.quantity = item.quantity
    this.amount = item.amount
    this.index = Number(index)
  }

  calAmount() {
    this.amount = 0
    if (this.unitPrice && this.quantity) {
      this.amount = this.unitPrice * this.quantity
    }
  }

  callTotalAmount() {
    this.totalAmount = 0
    if (this.listJobs && this.listJobs.length) {
      this.totalAmount = _.sumBy(this.listJobs, job => job.amount)
    }
  }

  close() {
    this.popoverCtrl.dismiss()
  }

  next() {
    if (!this.quotation && !this.isGenerateInvoice) {
      this.utils.showToastError('Please input Quotation #!')
      return
    }
    if (!this.invoiceId && this.isGenerateInvoice) {
      this.utils.showToastError('Please input Invoice #!')
      return
    }
    if (!this.listJobs.length) {
      this.utils.showToastError('Please add at least a job!')
      return
    }
    this.isShowPreview = true
  }

  async downloadPDF() {
    if (!this.quotation && !this.isGenerateInvoice) {
      this.utils.showToastError('Please input Quotation #!')
      return
    }
    if (!this.invoiceId && this.isGenerateInvoice) {
      this.utils.showToastError('Please input Invoice #!')
      return
    }
    if (!this.listJobs.length) {
      this.utils.showToastError('Please add at least a job!')
      return
    }
    if (!this.pdfUrl) {
      await this.generatePDF()
    }
    window.open(this.pdfUrl, '_blank')
  }

  async send() {
    if (!this.pdfUrl) {
      this.pdfUrl = await this.generatePDF()
    }
    if (this.isGenerateInvoice) {
      this.showModalConfirmOilChange()
    } else {
      const params = {
        totalPrice: _.sumBy(this.listJobs, item => item.amount),
        quotationId: this.quotation,
        pdfUrl: this.pdfUrl,
        pdfName: this.pdfName
      }
      this.popoverCtrl.dismiss(params)
    }
  }

  showModalConfirmOilChange() {
    this.goCarPopover.show({
      component: ModalConfirmOilChangeComponent,
      cssClass: 'modal-configm-oil-change'
    }).then(async (rs) => {
      if (rs) {
        await this.popoverCtrl.dismiss()
        const params = {
          totalPrice: _.sumBy(this.listJobs, item => item.amount),
          invoiceId: this.invoiceId,
          pdfUrl: this.pdfUrl,
          pdfName: this.pdfName,
          isPeriodicService: rs.isChange
        }
        this.popoverCtrl.dismiss(params)
      }
    })
  }

  async generatePDF() {
    this.pdfUrl = ''
    this.pdfName = `${this.isGenerateInvoice ? 'Invoice' : 'Quotation'}_Generation_${(new Date()).getTime()}`
    const params = {
      quotation: this.quotation,
      invoice: this.invoiceId,
      today: this.today,
      userInfo: this.userInfo.firstName + ' ' + this.userInfo.lastName,
      clientInfo: this.bookingInfo.clientInfo,
      vehicleInfo: this.bookingInfo.vehicleInfo,
      mileage: this.decimalPipe.transform(this.bookingInfo.mileage || 0) + ' KM',
      registration: this.bookingInfo.registration,
      listJob: this.listJobs,
      remark: this.remark || '',
      pdfName: this.pdfName,
      garageName: this.userInfo.garageName || ''
    }

    try {
      this.loadingComponent.showLoading()
      let rs
      if (this.isGenerateInvoice) {
        rs = await this.PDFService.invoiceQuotationPDF(params).toPromise()
      } else {
        rs = await this.PDFService.generateQuotationPDF(params).toPromise()
      }
      this.pdfUrl = rs && rs.data
      return rs && rs.data
    } catch (error) {
      this.utils.showToastError(error && error.message)
    } finally {
      this.loadingComponent.hideLoading()
    }
  }
}
