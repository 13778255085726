import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController, NavParams, Platform } from '@ionic/angular';
import * as _ from 'lodash';
import { LoadingComponent } from 'src/app/shared/component/loading/loading';
import { GoCarPopover } from 'src/app/shared/component/popover/gocar-popover';
import { SparePartShopService } from 'src/app/shared/services/shop/shopping-cart.service';
import { Utils } from 'src/app/shared/services/utils/utils.service';

interface OrderItem {
  id: string;
  image: string;
  name: string;
  quantity: number;
  price: number;
  productId: string;
  productOptions?: any[];
  productOptionId?: string;
  optionName?: string;
}

@Component({
  selector: 'app-order-item-list',
  templateUrl: './order-item-list.component.html',
  styleUrls: ['./order-item-list.component.scss'],
})
export class OrderItemListComponent implements OnInit {
  @Input() orderId: string;

  public orderItems: OrderItem[] = [];
  public isMobile;

  constructor(
    private platform: Platform,
    private route: ActivatedRoute,
    public goCarPopover: GoCarPopover,
    private utils: Utils,
    private sparePartShopService: SparePartShopService,
    private loadingComponent: LoadingComponent) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id') || this.orderId;

    this.getOrderItems(id);
    this.platform.ready().then(() => {
      this.isMobile = this.platform.width() < 768;
    });
  }

  private async getOrderItems(orderId: string) {
    try {
      this.loadingComponent.showLoading();
      const results = await this.sparePartShopService.getOrderItems(orderId).toPromise();
      this.orderItems = results.data || [];
      this.orderItems.forEach(item => {
        const optionSelected = _.find(item.productOptions, option => option.id === item.productOptionId);
        item.optionName = optionSelected && optionSelected.name || '';
      });

    } catch (error) {
      this.utils.showToastError(error.message);
    } finally {
      this.loadingComponent.hideLoading();
    }
  }
}
