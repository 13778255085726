import { Injectable } from "@angular/core";
import { BaseService } from "../../services/base.service";
import { Http } from "@angular/http";
import { Router } from "@angular/router";
import { environment as config } from '../../../../environments/environment';
import { NavController } from "@ionic/angular";

@Injectable()
export class PDFService extends BaseService {
  constructor(public http: Http, public router: Router, protected navCtrl: NavController) {
    super(http, router, navCtrl)
    this.apiUrl = config.PDF_SERVICE_API;
  }

  generateQuotationPDF(data) {
    return this.postData('pdf/quotation-generation', data).map((res: Response) => res.json())
  }

  invoiceQuotationPDF(data) {
    return this.postData('pdf/invoice-generation', data).map((res: Response) => res.json())
  }
}