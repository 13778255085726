import { Injectable, EventEmitter } from '@angular/core';
import * as io from 'socket.io-client';
import { environment as config } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: any;
  public statusTaskChangeReceived = new EventEmitter<string>();

  constructor() {
    this.socket = io(config.API_URL_GOCAR_GARAGE.replace('/gocar-garage-api', ''));
    // Listen for the 'message' event
    this.socket.on('status_task_change', (bookingId: string) => {
      this.statusTaskChangeReceived.emit(bookingId);
    });

    this.socket.on('reconnect', () => {
      // Emit "updateSocketId" event to update the recorded socket ID
      this.socket.emit('updateSocketId', error => {
        if (error) {
          console.error(error);
        }
      });
    });
  }

  // Send a message to the server
  public emitEvent(event: string): void {
    this.socket.emit(event);
  }
}
